<template>
  <div class="change-service">
    <h2>{{ $t('Choose other services') }}</h2>
    <h3>{{ $t('We have got 3 services for your business') }}</h3>

    <div class="change-service__cards">
      <ChangeServiceCard
        v-for="service in services"
        :key="service.title"
        class="change-service__card"
        :title="service.title"
        :description="service.description"
        :link="isActive(service) ? null : service.link"
        :imgSrc="service.imgSrc"
        :disabled="service.disabled"
        :active="isActive(service)"
        :beta="service.beta"
        @click.native="serviceCardClick(service)"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { services } from '@/utils/constants/service'

import { currentWorkspaceId } from '@/mixins/workspace'
import { currentServiceId } from '@/mixins/routes/services'

import ChangeServiceCard from '@/components/changeService/ChangeServiceCard'

export default {
  name: 'ChangeService',
  components: {
    ChangeServiceCard,
  },
  mixins: [
    currentWorkspaceId,
    currentServiceId,
  ],

  computed: {
    // это вычисляемое свойство, чтобы при переключении языка менялся текст
    services () {
      return [
        {
          id: services.TASK_MANAGER,
          title: this.$t('Tasks'),
          description: this.$t('tasks-description'),
          link: `/ws/${this.currentWorkspaceId}`,
          imgSrc: '/img/services/tasks.png',
          beta: false,
        },
        {
          id: services.KNOWLEDGE_BASE,
          title: this.$t('Base'),
          description: this.$t('base-description'),
          link: `/ws/${this.currentWorkspaceId}/pages/`,
          imgSrc: '/img/services/base.png',
          beta: true,
        },
        {
          id: services.EDUCATION,
          title: this.$t('Education'),
          description: this.$t('education-description'),
          disabled: true,
          imgSrc: '/img/services/education.png',
          beta: false,
        },
      ]
    },
  },

  methods: {
    ...mapMutations('services', [
      'HIDE_CHOOSE_SERVICE_MODAL',
    ]),
    ...mapActions('services', [
      'setService',
    ]),

    isActive (service) {
      return service.id === this.currentServiceId
    },
    serviceCardClick (service) {
      // если это активный сервис
      if (service.id === this.currentServiceId) {
        this.HIDE_CHOOSE_SERVICE_MODAL()
      }
      // если есть куда переходить - при переходе установим крайний посещаемый сервис
      else if (service.link) {
        this.setService(service.id)
      }
    },
  },

  i18n: {
    messages: {
      'en': {
        'Choose other services': 'Choose other services',
        'We have got 3 services for your business': 'We have got 3 services for your business',

        'Tasks': 'Tasks',
        'tasks-description': 'Service for project and task management',

        'Base': 'Base',
        'base-description': 'Knowledge base for your company',

        'Education': 'Education',
        'education-description': 'Articles and videos for self-education and improving Your company',
      },
      'ru': {
        'Choose other services': 'Попробуйте другие наши сервисы',
        'We have got 3 services for your business': 'Воспользуйтесь этими удобными сервисами для вашего бизнеса',

        'Tasks': 'Задачи',
        'tasks-description': 'Сервис управления проектами и задачами',

        'Base': 'База знаний',
        'base-description': 'База знаний для вашей компании',

        'Education': 'Образование',
        'education-description': 'Статьи и видео для самообразования и&nbsp;улучшения эффективности вашей компании',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .change-service {
    height: 100%;
    background: $lightThemeBackground2;
    $root: &;

    @include dark-theme {
      background: $darkThemeBgTwo;
    }

    h2 {
      @include flexCenter;
      margin-top: 136px;
      margin-bottom: 0;
    }

    h3 {
      @include flexCenter;
      margin-top: 5px;
      margin-bottom: 0;
    }

    &__cards {
      @include flexCenter;
      margin-top: 54px;

      #{$root}__card:not(:first-child) {
        margin-left: 55px;
      }
    }
  }
</style>
