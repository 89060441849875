import { mapState, mapGetters, mapMutations } from 'vuex'

export const currentWorkspaceId = {
  computed: {
    ...mapState('workspace', {
      wsId: 'currentWorkspaceId',
    }),

    currentWorkspaceId () {
      const currentWorkspaceId = this.$route.params.wsId ?? this.wsId

      if (
        !!currentWorkspaceId &&
        (this.$route.params.wsId) &&
        (this.wsId !== +currentWorkspaceId)
      ) {
        this.SET_CURRENT_WORKSPACE(+currentWorkspaceId)
      }

      return +currentWorkspaceId
    },
  },

  methods: {
    ...mapMutations('workspace', [
      'SET_CURRENT_WORKSPACE',
    ]),
  },
}

export const currentWorkspace = {
  mixins: [
    currentWorkspaceId,
  ],

  computed: {
    ...mapGetters('workspace', [
      'getWorkspace',
    ]),

    currentWorkspace () {
      return this.getWorkspace(this.currentWorkspaceId)
    },
  },
}

export const currentWorkspaceIsPersonal = {
  mixins: [
    currentWorkspace,
  ],

  computed: {
    currentWorkspaceIsPersonal () {
      return this.currentWorkspace.isPersonal
    },
  },
}

export const getWorkspaceAvatar = {
  computed: {
    ...mapState('general', [
      'no_image',
    ]),

    ...mapState('user', [
      'user',
    ]),
  },

  methods: {
    getWorkspaceAvatar (workspace) {
      return workspace.isPersonal
        ? this.user?.logoLink || this.no_image
        : workspace.logo
    },
  },
}
