import { services } from '@/utils/constants/service'

export const kbRoutes = [
  'ws-kb',
  'ws-kb-page',
]

export const rootServiceRoutes = (() => {
  const rootServiceRoutesList = {}
  rootServiceRoutesList[services.TASK_MANAGER] = 'ws-home'
  rootServiceRoutesList[services.KNOWLEDGE_BASE] = 'ws-kb'
  return rootServiceRoutesList
})()
