<template>
  <router-link
    class="service-card"
    :class="{ disabled, active }"
    :to="link"
    :is="link ? 'router-link' : 'div'"
  >
    <div
      :style="{ 'background-image': `url(${imgSrc})` }"
      class="service-card__cover"
    >
      <div
        v-if="beta || soon"
        class="service-card__badge"
        :class="{ beta, soon }"
      >
        {{ badgeText }}
      </div>
    </div>

    <div class="service-card__title">
      <div class="service-card__title-text">
        {{ title }}
      </div>

      <div v-if="active" class="service-card__service-is-active">
        {{ $t('Active') }}
      </div>
    </div>

    <div
      v-html="description"
      class="service-card__description"
    ></div>
  </router-link>
</template>

<script>
export default {
  name: 'ChangeServiceCard',

  props: {
    title: String,
    description: String,
    link: [String, Object],
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    imgSrc: String,
    beta: Boolean,
  },
  computed: {
    soon () {
      return this.disabled
    },
    badgeText () {
      if (this.beta) {
        return this.$t('Beta')
      }

      if (this.soon) {
        return this.$t('Soon')
      }

      return null
    },
  },

  i18n: {
    messages: {
      'en': {
        'Active': 'Active',
        'Beta': 'Beta',
        'Soon': 'Soon',
      },
      'ru': {
        'Active': 'Сервис активен',
        'Beta': 'Бета',
        'Soon': 'Скоро',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .service-card {
    display: flex;
    flex-direction: column;
    width: 227px;
    color: inherit;
    text-decoration: none;
    $root: &;

    &__cover {
      position: relative;
      height: 108px;
      width: 100%;
      background-color: #FFFFFF;
      background-size: cover;
      background-position: right;
      box-shadow: 0 4px 24px rgba(61, 162, 255, 0.04);
      border-radius: 8px;
    }

    &__badge {
      position: absolute;
      min-width: 62px;
      padding: 4px 10px;
      border-radius: 4px;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      text-align: center;
      top: 14px;
      right: 14px;

      &.beta {
        background-color: #3DA2FF;
        color: #FDFDFD;
      }

      &.soon {
        background-color: #DCDDDE;
      }
    }

    &__title {
      display: flex;
      margin-top: 21px;
      justify-content: space-between;

      &-text {
        font-size: 16px;
        font-weight: bold;
      }

      #{$root}__service-is-active {
        font-size: 12px;
        font-weight: 300;
        color: $blue;

        &:before {
          content: ' ';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          vertical-align: middle;
          background: $blue;
        }
      }
    }

    &__description {
      display: flex;
      margin-top: 14px;
    }

    &.active {
      #{$root}__cover {
        border: 2px solid $blue;
        box-sizing: border-box;
        box-shadow: 0 4px 24px rgba(61, 162, 255, 0.04);
      }
    }

    &:not(.disabled) {
      cursor: pointer;
    }
  }
</style>
