import { services } from '@/utils/constants/service'

import { kbRoutes } from '@/utils/constants/routes/service'

export const getCurrentService = {
  data: () => ({
    // подменяем название, чтоб на странице не было наложений с локальными переменными
    servicesConstants: services,
    kbRoutes,
  }),
  methods: {
    getCurrentService () {
      return this.kbRoutes.includes(this.$route.name)
        ? this.servicesConstants.KNOWLEDGE_BASE
        : this.servicesConstants.TASK_MANAGER
    },
  },
}

export const currentServiceId = {
  mixins: [
    getCurrentService,
  ],

  computed: {
    currentServiceId () {
      return this.getCurrentService()
    },
  },
}
